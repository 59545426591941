import { cloneDeep } from "lodash";
import * as React from "react";
import Modal from "react-modal";
import styled from "styled-components/macro";
import { COLORS } from "../utils/colors";
import { customModalStyles } from "../utils/constants";
import { sizes } from "../utils/sizes";
import { BoldSecondaryText, SmallSecondaryText } from "./common";
import { BigWhiteLock } from "./icons";

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${COLORS.nearWhite};
`;

const Container = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${sizes.small}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const FAQs = [
  {
    question: "Does tryapis.com store data from my API requests or responses?",
    answer: (
      <>
        {" "}
        No, we never store the parameters you pass into API requests or the
        responses you receive. API parameters are persisted locally in your
        browser (in local storage) for convenience. The only data we log is
        which endpoints are getting called (to keep track of popular API
        endpoints) and the response codes received from those endpoints (e.g.
        "401", to help us identify hard-to-query endpoints).
      </>
    ),
  },
  {
    question: `Why don't my API requests go directly to the API service I am
    calling?`,
    answer: (
      <>
        {" "}
        Most APIs on tryapis.com don't allow making requests directly from the
        browser because of{" "}
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS"
          target="_blank"
          rel="noreferrer"
        >
          CORS
        </a>{" "}
        restrictions. To circumvent this, we send API requests to an AWS Lambda
        function which forwards the request to the service (a "proxy").{" "}
        <strong>
          If you prefer, you can avoid this by sending requests from your
          terminal using our "Copy cURL request" feature
        </strong>{" "}
        or by using "Generate Code" to make a request from your favorite
        programming language.
      </>
    ),
  },
  {
    question: `Do I have to give tryapis.com OAuth access to the services I'm querying?`,
    answer:
      "For most APIs, we let you authorize tryapis.com using an Oauth flow, which can be revoked at any time in the dashboard of the service you are querying (e.g. in the Github UI when granting access to the Github API). This is the fastest way to get started since it usually involves only 2 clicks. However, if you prefer, you can always generate an Oauth access token indepentently of tryapis.com and copy/paste it into the Authorization field.",
  },
];

export const SecurityModal = ({
  modalOpen,
  setModalOpen,
}: {
  modalOpen: boolean;
  setModalOpen: (o: boolean) => void;
}) => {
  const modalStyle = cloneDeep(customModalStyles);
  modalStyle.content.height = "80%";
  modalStyle.content.top = "50%";
  modalStyle.content.maxWidth = 600;

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      style={modalStyle}
      contentLabel="Example Modal"
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "unset")}
    >
      <div style={{ display: "flex", alignItems: "center", marginBottom: 24 }}>
        <BigWhiteLock />
        <Title>Security</Title>
      </div>
      <Container>
        {FAQs.map((f, i) => (
          <div key={i}>
            <BoldSecondaryText style={{ marginBottom: 12 }}>
              {f.question}
            </BoldSecondaryText>
            <SmallSecondaryText>{f.answer}</SmallSecondaryText>
          </div>
        ))}
      </Container>
    </Modal>
  );
};
